import React from "react"
import styled from "styled-components"

const Burger = styled.div`
  position: absolute;
  z-index: 11;
  width: ${props => props.size}px;
  height: ${props => props.size * 0.7}px;
  transform: rotate(0deg) translateY(-50%);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  top: 3rem;
  right: 1rem;

  & span {
    display: block;
    position: absolute;
    height: ${props => props.size * 0.125}px;
    width: 100%;
    background: #ffffff;
    border-radius: ${props => props.size * 0.125}px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: ${props => (props.open ? props => props.size * 0.25 + "px" : "0px")};
    width: ${props => (props.open ? "0%" : "100%")};
    left: ${props => (props.open ? "50%" : "0")};
  }

  span:nth-child(2) {
    top: ${props => props.size * 0.25}px;
    transform: rotate(${props => (props.open ? "45deg" : "0deg")});
  }

  span:nth-child(3) {
    top: ${props => props.size * 0.25}px;
    transform: rotate(${props => (props.open ? "-45deg" : "0deg")});
  }

  span:nth-child(4) {
    top: ${props =>
      props.open
        ? props => props.size * 0.25 + "px"
        : props => props.size * 0.5 + "px"};
    width: ${props => (props.open ? "0%" : "100%")};
    left: ${props => (props.open ? "50%" : "0")};
  }
`

export default ({ onClick, className, size, navOpen }) => {
  return (
    <Burger
      role="button"
      tab-index="0"
      className={className}
      onClick={onClick}
      size={size}
      open={navOpen}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </Burger>
  )
}
