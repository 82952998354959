import React, { useState, useRef, useEffect } from "react"
import classNames from "classnames"
import LazyLoad from "react-lazyload"

const ClientBar = ({ logos }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  useInterval(() => {
    setActiveIndex(activeIndex < logos.length - 1 ? activeIndex + 1 : 0)
  }, 3000)

  const logoClasses = index =>
    classNames("client-bar__logo", {
      "client-bar__logo--active": index === activeIndex,
    })

  return (
    <section id="clients" className="client-bar">
      <LazyLoad height={200} once={true}>
        <div className="client-bar__logo-container">
          {logos.map((brand, index) => (
            <img
              key={brand}
              className={logoClasses(index)}
              src={require(`../images/${brand}.png`)}
              alt={brand}
            />
          ))}
        </div>
      </LazyLoad>
    </section>
  )
}

const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default ClientBar
