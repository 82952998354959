import React, { useState } from "react"
import { Link, animateScroll as scroll } from "react-scroll"
import classNames from "classnames"

import Burger from "../components/burger"
import Logo from "../images/logo.png"

const Navigation = ({bodyRef}) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const drawerClasses = classNames("navigation__drawer", {
    "navigation__drawer--open": drawerOpen,
  })

  const burgerClasses = classNames("navigation__burger", {
    "navigation__burger--fixed": drawerOpen,
  })

  const handleClick = () => {
    setDrawerOpen(!drawerOpen)
    bodyRef.current.classList.toggle('nav-open')
  }

  return (
    <nav className="navigation">
      <div className="navigation__inner">
        <img
          className="navigation__logo"
          src={Logo}
          alt="AF First Aid"
        />
        <Burger
          className={burgerClasses}
          size={30}
          navOpen={drawerOpen}
          onClick={handleClick}
        />
        {renderDrawer(drawerClasses, handleClick)}
        {renderInlineNav()}
      </div>
    </nav>
  )
}

const renderInlineNav = () => {
  return (
    <div className="navigation__inline-nav">
      <ul className="navigation__inline-nav-list">
        <li className="navigation__inline-nav-listitem">
          <Link
            className="navigation__inline-nav-listitem-link"
            to="introduction"
            smooth={true}
            offset={0}
            duration={500}
          >
            About
          </Link>
        </li>
        <li className="navigation__inline-nav-listitem">
          <Link
            className="navigation__inline-nav-listitem-link"
            to="services"
            smooth={true}
            offset={0}
            duration={500}
          >
            Services
          </Link>
        </li>
        <li className="navigation__inline-nav-listitem">
          <Link
            className="navigation__inline-nav-listitem-link"
            to="clients"
            smooth={true}
            offset={0}
            duration={500}
          >
            Clients
          </Link>
        </li>
        <li className="navigation__inline-nav-listitem">
          <Link
            className="navigation__inline-nav-listitem-link"
            to="contact"
            smooth={true}
            offset={0}
            duration={500}
          >
            Contact
          </Link>
        </li>
      </ul>
    </div>
  )
}

const renderDrawer = (drawerClasses, handleClick) => {
  return (
    <div className={drawerClasses}>
      <ul className="navigation__drawer-list">
        <li className="navigation__drawer-listitem">
          <Link
            className="navigation__drawer-listitem-link"
            to="introduction"
            smooth={true}
            offset={0}
            duration={500}
            onClick={handleClick}
          >
            About
          </Link>
        </li>
        <li className="navigation__drawer-listitem">
          <Link
            className="navigation__drawer-listitem-link"
            to="services"
            smooth={true}
            offset={0}
            duration={500}
            onClick={handleClick}
          >
            Services
          </Link>
        </li>
        <li className="navigation__drawer-listitem">
          <Link
            className="navigation__drawer-listitem-link"
            to="clients"
            smooth={true}
            offset={0}
            duration={500}
            onClick={handleClick}
          >
            Clients
          </Link>
        </li>
        <li className="navigation__drawer-listitem">
          <Link
            className="navigation__drawer-listitem-link"
            to="contact"
            smooth={true}
            offset={0}
            duration={500}
            onClick={handleClick}
          >
            Contact
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Navigation
