import React from "react"
import styled from "styled-components"

const FormItem = styled.div``

const Label = styled.label`
  color: #343434;
  font-weight: bold;
  font-variant-ligatures: common-ligatures;
  font-size: 1rem;
  line-height: 1.4;
  margin: 1rem 0 0.5rem 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  display: block;
`

const Textarea = styled.textarea`
  background-color: #efefef;
  border-radius: 1rem;
  border: none;
  color: #767676;
  padding: 1rem 1.75rem;
  width: 100%;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #fedd00;
  }
`

export default ({
  labelText,
  placeholder,
  className,
  type,
  onChange,
  rows,
  name
}) => {
  const uniqueId = Math.random().toString(36).substring(7)
  return (
    <FormItem className={className}>
      <Label className={`${className}__label`} htmlFor={uniqueId}>
        {labelText}
      </Label>
      <Textarea
        id={uniqueId}
        name={name || type && type.toLowerCase()}
        className={`${className}__input`}
        type={type || "text"}
        placeholder={placeholder}
        onChange={onChange}
        rows={rows || 8}
      />
    </FormItem>
  )
}
