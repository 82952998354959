import React from "react"
import styled from "styled-components"

const Button = styled.button`
  font-family: "Helvetica", "Arial", sans-serif;
  transition: 0.1s linear;
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem 3rem;
  text-transform: uppercase;
  background-color: ${props =>
    props.outlined
      ? "transparent"
      : props => props.backgroundColor || "#425cbb"};
  border: ${props => `1px ${props.backgroundColor} solid`};
  font-size: 0.75rem;
  letter-spacing: 1px;
  color: ${props => props.textColor || "#ffffff"};
  transition: opacity 0.25s ease-out;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #fedd00;
  }

  &:hover {
    transition: opacity 0.25s ease-in;
    opacity: 0.75;
    cursor: pointer;
  }
`

export default ({
  className,
  children,
  onClick,
  textColor,
  backgroundColor,
  type,
  name,
  outlined,
}) => {
  return (
    <Button
      type={type || "button"}
      className={className}
      onClick={onClick}
      backgroundColor={backgroundColor}
      textColor={textColor}
      outlined={outlined}
      name={name || type && type.toLowerCase()}
    >
      {children}
    </Button>
  )
}
