import React, { useRef } from "react"
import LazyHero from "react-lazy-hero"
import { Parallax } from "react-parallax"
import { navigate } from "gatsby-link"
import LazyLoad from "react-lazyload"
import { Slide } from "react-awesome-reveal"
import { Link, animateScroll as scroll } from "react-scroll"

import SEO from "../components/seo"
import ServiceCard from "../components/service_card"
import Navigation from "../components/navigation"
import Button from "../components/button"
import ClientBar from "../components/client_bar"
import Map from "../components/map"
import Input from "../components/input"
import Textarea from "../components/textarea"

import CONTENT from "../../content"
import bgImage from "../images/header-bg.jpg"
import ssdLogo from "../images/powered-by-ssd.png"
import AndyFarley from "../images/andy-farley.png"

import "../scss/main.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const IndexPage = () => {
  const [state, setState] = React.useState({})
  const bodyRef = useRef(null)

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <div ref={bodyRef}>
      <SEO title="AF First Aid Ltd" />
      <header className="header">
        <Navigation bodyRef={bodyRef} />
        <LazyHero
          className="header__hero"
          imageSrc={bgImage}
          minHeight="90vh"
          color="#000000"
          opacity={0.4}
          parallaxOffset={50}
        >
          <Slide triggerOnce fraction={0.25} triggerOnce>
            <h1 className="header__title">{CONTENT.header.title}</h1>
            <h2 className="header__subtitle">{CONTENT.header.subtitle}</h2>
          </Slide>
        </LazyHero>
      </header>
      <main>
        <section id="introduction" className="introduction">
          <Slide triggerOnce fraction={0.25} triggerOnce>
            <h2 className="introduction__title">
              {CONTENT.introduction.title}
            </h2>
            <div className="introduction__container">
              <img className="introduction__image" src={AndyFarley} alt="Andy Farley" />
              <div
                className="introduction__content"
                dangerouslySetInnerHTML={{
                  __html: CONTENT.introduction.content_html,
                }}
              />
            </div>
          </Slide>
        </section>
        <section id="services" className="services">
          <h2 className="services__title">{CONTENT.services.title}</h2>
          <div className="services__card-container">
            {CONTENT.services.content.map((service, index) => {
            return <ServiceCard key={service.title} {...service} index={index} />
            })}
          </div>
        </section>
        <ClientBar logos={CONTENT.clientBar.brands} />
        <section className="catalogue-bar">
          <LazyLoad height={200} once={true}>
            <Parallax
              bgImage={require("../images/landscape.jpg")}
              bgImageAlt="the cat"
              strength={200}
            >
              <Slide triggerOnce fraction={0.25} cascade className="catalogue-bar__container">
                <p className="catalogue-bar__content">
                  {CONTENT.catalogueBar.content}
                </p>
                <Link
                  to="contact-form"
                  smooth={true}
                  offset={0}
                  duration={500}
                  className="catalogue-bar__button"
                >
                  {CONTENT.catalogueBar.ctaText}
                </Link>
              </Slide>
            </Parallax>
          </LazyLoad>
        </section>
        <section id="contact" className="contact">
          <h2 className="contact__title">{CONTENT.contact.title}</h2>
          <div
            className="contact__address"
            dangerouslySetInnerHTML={{ __html: CONTENT.contact.address_html }}
          />
          <LazyLoad height={200} once={true}>
            <Map isMarkerShown={true} />
          </LazyLoad>
          <div
            className="contact__content"
            dangerouslySetInnerHTML={{ __html: CONTENT.contact.content_html }}
          />
          <form
            id="contact-form"
            className="contact__form-container"
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <p className="hidden">
              <label>
                Don’t fill this out if you're human:{" "}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <Input
              labelText="Name"
              name="name"
              placeholder="Enter your full name"
              className="contact__name"
              onChange={handleChange}
            />
            <Input
              labelText="Email"
              name="email"
              placeholder="Enter your email address"
              type="email"
              className="contact__email"
              onChange={handleChange}
            />
            <Input
              labelText="Phone Number"
              name="number"
              placeholder="Enter your phone number"
              type="text"
              className="contact__phone-number"
              onChange={handleChange}
            />
            <Input
              labelText="Subject"
              name="subject"
              placeholder="What can we help you with?"
              className="contact__subject"
              onChange={handleChange}
            />
            <Textarea
              labelText="Message"
              name="message"
              placeholder="Enter your message"
              className="contact__message"
              onChange={handleChange}
            />
            <div data-netlify-recaptcha="true"></div>
            <Button
              className="contact__button"
              backgroundColor="#407e39"
              textColor="#ffffff"
              type="submit"
              name="submit"
            >
              Submit
            </Button>
          </form>
        </section>
      </main>
      <footer className="footer">
        <LazyLoad height={200} once={true}>
          <a href="https://www.softselldigital.com"><img
            className="footer__logo"
            src={ssdLogo}
            alt="Powered by Soft Sell Digital"
          /></a>
        </LazyLoad>
      </footer>
    </div>
  )
}

export default IndexPage
