import React from "react"
import { FaHome, FaPencilAlt, FaBookOpen, FaShoppingCart } from "react-icons/fa"

const ServiceCard = ({ title, logo, content }) => {
  const componentMap = {
    van: <FaHome />,
    order: <FaPencilAlt />,
    experience: <FaBookOpen />,
    cart: <FaShoppingCart />,
  }

  return (
    <article className="service-card">
      {componentMap[logo]}
      <h3 className="service-card__title">{title}</h3>
      <p className="service-card__content">{content}</p>
    </article>
  )
}

export default ServiceCard
