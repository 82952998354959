export default {
  header: {
    title: "AF First Aid Supplies",
    subtitle:
      "Unique First Aid Van Sales Service & Consultancy - Serving the North West for over 19 years",
  },
  introduction: {
    title: "A Personal Message from The Owner of AF First Aid",
    content_html: `
    <div class="page_content">
      <p>A warm welcome to A F First Aid! Thanks for visiting us. We are a traditional, consultative first aid company.</p>
      <p>We solely run our sales offline and deliver everywhere in the North West & sometimes beyond.</p>
      <p>Place your order or ask us to come and complete a free assessment of your premises. The Vans are always stocked, once we complete your assessment, we can leave you with all the supplies you require.</p>
      <p class="bold">If you know what you need, give us a call or fill out a form and we can deliver to you quickly. Don’t risk ordering online, let our experts visit you and tell you what you need.</p>
    </div>`,
  },
  services: {
    title: "Our Services",
    content: [
      {
        title: "Unique Van Sale Service",
        logo: "van",
        content:
          "Our unique van sale service allows AF First Aid to come direct to you without the hassle of ordering over the phone or internet. We come direct to your door and deliver anywhere in the North West",
      },
      {
        title: "Order anytime, anywhere",
        logo: "order",
        content:
          "Whether you are in the office, on the road or away on business AF First Aid is only a phone call away and will take any orders anytime, anywhere. Just call us on 07980 4525858. Let us do the hard work.",
      },
      {
        title: "Over 15 years experience",
        logo: "experience",
        content:
          "AF First Aid has over 15 years experience of supplying first supplies. That’s why we use our knowledge and experience to offer clients, both large and small, a unique service that leaves you happy and satisfied.",
      },
      {
        title: "All needs catered for",
        logo: "cart",
        content:
          "At AF First Aid there is no minimum order quantity and no minimum spend. We can delivery direct to you or post out orders to your premises. Any postal orders will incur a P&P charge.",
      },
    ],
  },
  clientBar: {
    brands: ["costco", "mercedes", "cottons", "audi", "liverpool-cathedral"],
  },
  catalogueBar: {
    content:
      "Click here to request a callback and see what we can do for you.",
    ctaText: "Request callback",
  },
  contact: {
    title: "Contact us",
    address_html: `
    <p>Our offices are based in Holmeswood, near Southport:-</p><br>
    <div class="address">
      <p>AF First Aid</p>
      <p>Homestead</p>
      <p>Holmeswood Road</p>
      <p>Holmeswood</p>
      <p>Lancashire</p>
      <p>L40 1TZ</p>
    </div>`,
    content_html:`
    <div class="page_content">
      <p>Whether you have a query, order or just want to get in touch we’d love to hear from you. You can contact us via</p>
      <p>Phone: <a href="tel:07890452585">07890 452585</a></p>
      <p>Email: <a href="mailto:sales@affirstaid.co.uk">sales@affirstaid.co.uk</a></p>
      <p>Fill out the form below and we will get back to you as soon as possible.</p>
    </div>`,
  },
}
